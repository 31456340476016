var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.checkPageData)?_c('div',{attrs:{"id":"pagination-accommodation"}},[_c('ul',{staticClass:"pagination m-tb0"},_vm._l((_vm.paginator),function(number,index){return _c('li',{key:index,staticClass:"scroll-page"},[(number !== 0)?_c('nuxt-link',{class:{ 'page-active' : (_vm.pageNumber == number) },attrs:{"to":_vm.getLocalizedRoute({
                        name: _vm.params.currentRouteName
                        , params: {
                            category: _vm.$tradLinkSlug(
                                _vm.$store.state.i18n.currentLocale
                                , _vm.params.currentCategory
                                , _vm.getCategoryType
                            )
                        }
                        , query: { page: number }
                    }),"no-prefetch":""}},[_vm._v("\n\t      \t\t\t"+_vm._s(number)+"\n\t      \t")]):_vm._e()],1)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
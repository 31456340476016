var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"masonry-filter font-weight-600",attrs:{"id":"isotope_menu_default"}},[_vm._l((_vm.data),function(value,index){return _c('li',{key:index,staticClass:"button implicit subsection tagcloud"},[_c('nuxt-link',{class:{ 'nuxt-link-exact-active' : (_vm.routeCategory == value.slug) },attrs:{"to":_vm.getLocalizedRoute({
                name: _vm.params.routeCategoryName
                , params: {
                    category: _vm.$tradLinkSlug(
                        _vm.$store.state.i18n.currentLocale
                        , value.slug
                        , 'accommodationType'
                    )
                }
            })}},[_vm._v("\n            "+_vm._s(_vm.$t(value.name))+"\n        ")])],1)}),_vm._v(" "),_c('li',{staticClass:"button implicit subsection tagcloud all"},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({ name: _vm.params.routeName })}},[_vm._v("\n           "+_vm._s(_vm.$t('Tout'))+"\n       ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }